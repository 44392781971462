<template>
    <div>
        <topmenu :comname="'forum'"></topmenu>
        <div class="widht1240">
            <div class="t-map">您当前的位置：<span>同期活动</span></div>
        <div class="widht200 fl">
        <leftmenulist :title="'同期活动'" :keyid="id"></leftmenulist>
        <contact></contact>
        </div>
        <div class="widht1030 bg-white fr">
            <div class="pa30">
                <div class="title">{{vName}}</div>
                <div class="time">发布时间：{{time| formatDate}}<span class="pl10">来源：{{source}}</span></div>
                <div class="zhaiyao" v-if="vAbstract!=null&&vAbstract!=''">{{vAbstract}}</div>
                <div class="content" v-html="vContent"></div>
            </div>
        </div>
        </div>
        <bottominfo></bottominfo>
    </div>
</template>
<script>
import topmenu from '@/components/public/top'
import bottominfo from '@/components/public/bottom'
import contact from '@/components/public/contact'
import leftmenulist from "@/components/public/menulist"
export default {
    name: 'forumdetails',
    data(){
        return{
            id:"",
            vName:"",
            time:'',
            vAbstract:"",
            vContent:"",
            source:""
        }
    },
    components:{
        topmenu,bottominfo,contact, leftmenulist
    },
    created(){
        if(this.$route.query.id){
            this.id=this.$route.query.id
        }
        this.getinformation();
    },
    watch:{
        $route: function(to, from) {
            this.id=this.$route.query.id;
            this.getinformation();
        }
    },
     methods:{
    getinformation() {
        let data={
            id: Number(this.id)
        }
      this.$axios.post("/api/app/exhibitionForum/getExhibitionForumById",data).then(res => {
        if (res[0].length > 0) {
          let infordata = res[0];
          console.log(infordata)
          this.vName=infordata[0].vName;
          this.time=infordata[0].dCreateTime;
          this.vAbstract=infordata[0].vAbstract;
          this.vContent=infordata[0].vContent;
         this.source=infordata[0].nvcNewsSource;
        } 
      })
    }
  }
}
</script>
<style scoped>
.pa30{
    padding: 30px;
}
.title{
    font-size: 24px;
    line-height: 60px;
    color: #000;
}
.time{
    color: #666;
    line-height: 30px;
}
.content {
  line-height: 28px;
  color: #333;
  text-align: left;
  margin-top: 20px;
}
.zhaiyao{
    background-color: #f8f8f8;
    padding: 20px;
    color: #333;
    text-indent: 2em;
    text-align: left;
    margin-top: 20px;
}
.pl10{
    padding-left: 20px;
}
</style>